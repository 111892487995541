import {
	Text as TextPrimitive,
	Blockquote as BlockquotePrimitive,
	Code as CodePrimitive,
	Em as EmPrimitive,
	Kbd as KbdPrimitive,
	Link as LinkPrimitive,
	Quote as QuotePrimitive,
	Strong as StrongPrimitive,
} from '@radix-ui/themes'
import React from 'react'

const TextElem = React.forwardRef<
	React.ElementRef<typeof TextPrimitive>,
	React.ComponentProps<typeof TextPrimitive>
>(({ children, className, ...props }, ref) => {
	return (
		<TextPrimitive ref={ref} className={className} {...props}>
			{children}
		</TextPrimitive>
	)
})
TextElem.displayName = 'TextElem'

const Blockquote = BlockquotePrimitive

const Code = CodePrimitive

const Em = EmPrimitive

const Kbd = KbdPrimitive

const LinkElem = LinkPrimitive

const Quote = QuotePrimitive

const Strong = StrongPrimitive

export { TextElem, Blockquote, Code, Em, Kbd, LinkElem, Quote, Strong }
